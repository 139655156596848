import Modal from 'react-bootstrap/Modal';
const ConsentModal = (props) => {
    return (
        <Modal size="xl" show={props.show} onHide={props.handleClose}>
            <Modal.Header closeButton>
            <Modal.Title>Privacy & Cookies Policy</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <p>The purpose of this policy is to set out in detail how Wilford Smith (“us” / “we”) use personal data. Wilford Smith Strategic Limited, trading as Wilford Smith is a limited company registered in England &amp; Wales, with a company number 07777022 and with a registered office at Meadowhall Business Park, S9 2EQ, Sheffield. Wilford Smith Limited is a law firm authorised and registered by Solicitors Regulation Authority (“SRA”) number 640898.</p>
                    <p>As a global privacy policy this document outlines the use across our whole organisation of personal data relating to our clients, personal data collected via our website and any other personal data handled by us.</p>
                    <p>We are committed to ensuring that the privacy of all those who provide us with personal data is protected. As a client of the firm you maybe asked to provide copies of documents such as passports, driving licences or house hold bills in order to comply with our regulatory requirements under anti – money laundering legislation. You can be assured that all of the important documents will be used in full compliance with this privacy policy.</p>
                    <p>This privacy policy also applies to our website and should we ask you to provide certain information by which you can be identified by contacting us online it will be used in full compliance with this privacy policy. By using our website, you consent to us processing and collecting this personal data on the terms and for the reasons outlined within this privacy policy.</p>
                    <p>This privacy policy will outline areas of our website that may effect your privacy and personal details , how we process, collect, manage and store those details and how your rights under the European General Data Protection Regulation (“GDPR”), the UK Data Protection Act 2018 (“DPA”) and the Privacy and Electronic Communications Regulations (“PECR”) are adhered to.</p>
                    <h3>Data Protection</h3>
                    <p>Wilford Smith is a data controller under GDPR and all associated legislation. All data processing activity is carried our subject to one of 6 legitimate business interests under Article 6 of GDPR. Your personal data maybe processed for different distinct purposes including regulatory compliance checks, provisions of legal services, billing and in order to notify you of any updates and amendments to laws, regulations and case law that may affect you or be of any interest.</p>
                    <p>We have nominated Mark Bates as the firm’s Data Privacy Manager.</p>
                    <p>Your personal data will be processed under the following legitimate basis in respect of each discrete activity:</p>
                    <ul>
                    <li>Pursuant to a contract – client related personal data will be collected and processed in order to provide the appropriate legal services required under the contract entered into by the law firm and the client.</li>
                    <li>Legal obligation – client related personal data will be collected, processed, transmitted, and retained under legal obligations such as, but not exclusively, compliance with the Money Laundering Act and our professional requirement to identify any possible conflicts of interest. Due to the nature of the legal obligation, this type of data collection and processing is not subject to the Right to be Forgotten or the Right to Erasure.</li>
                    <li>Legitimate business interest – client related personal data will be collected and retained, including personal contact details, and maintained in order to inform clients of any relevant updates and amendments to laws, regulations and case law that may affect them or be in the client’s best interests to be aware of.</li>
                    </ul>
                    <h3>Promotional Communications</h3>
                    <p>We may use your personal data to send you Wilford Smith updates and/or newsletters (by email, text, telephone or post) about legal developments that might be of interest to you and/or information about our services, including exclusive offers, promotions or new services. You have the right to opt out of receiving promotional communications at any time, by:</p>
                    <ul>
                    <li>Contacting us by email at <span id="cloak0ca5a0ee3dbf7901f53c972303f1bf6e"><a target="_blank" href="mailto:communications@wilfordsmith.com">communications@wilfordsmith.com</a></span> or by writing to Mark Bates, our Data Privacy Manager, at Wilford Smith Solicitors, MBP3, Meadowhall Business Park, Carbrook Hall Road, Sheffield S9 2EQ.</li>
                    <li>Or using the ‘unsubscribe’ link in the emails or ‘STOP’ number in text.</li>
                    </ul>
                    <h2>OUR PROFESSIONAL RESPONSIBILITIES</h2>
                    <p>We are required by law and our professional obligations to confirm satisfactory evidence of the identity of our clients and, sometimes, people related to them. This is because solicitors, who deal with money and property on behalf of their clients, can be used by criminals wanting to launder money.</p>
                    <p>To comply with the law, we need evidence of your identity as soon as possible. Please see our letter confirming your instructions. Any personal data we receive from you for the purpose of preventing money laundering or terrorist financing will be used for that purpose or:</p>
                    <ul>
                    <li>With your consent, or</li>
                    <li>As permitted by or under another enactment</li>
                    </ul>
                    <p>By statute, we are obliged to make disclosures to various government agencies across the public sector to comply with legal and regulatory requirements.</p>
                    <h2>What we Collect ON THE WEBSITE</h2>
                    <p>We may collect the following information:</p>
                    <ul>
                    <li>Your first and last name</li>
                    <li>Contact information including but not limited to email address, phone number, post code.</li>
                    <li>IP address</li>
                    <li>Any referring or exit pages taking you to or from our site</li>
                    <li>Login information such as your user name and password</li>
                    <li>Information regarding third-party sites or platforms, such as social networking sites. This may include information such as “likes”, profile information gathered from social networking sites or the fact that you viewed or interacted with our content.</li>
                    </ul>
                    <p><strong>What we do with the information we gather:</strong></p>
                    <p>We require this information to understand your needs and provide you with a better service, and in particular for the following reasons:</p>
                    <p><strong>Internal record keeping;</strong></p>
                    <ul>
                    <li>To diagnose and fix issues</li>
                    <li>To ensure content on our site is presented in the best way</li>
                    <li>To allow you to participate in interactive features</li>
                    <li>We also use your information to control unauthorised use or abuse of the site and our services. Or to detect, investigate or prevent activities that violate our policies or are illegal.</li>
                    </ul>
                    <p><strong>We also use your information to:</strong></p>
                    <ul>
                    <li>notify you of changes,</li>
                    <li>deliver customer service and respond to enquiries</li>
                    <li>administer the site using data analytics, testing, traffic monitoring, research, statistical and survey purposes.</li>
                    </ul>
                    <p>Where we have clearly stated and made you aware of the fact, and where you have given your express permission, except for where we have legitimate interests in doing so, we may use your details to send you information related to our services/products through a mailing list system. This is done in accordance with the regulations named above.</p>
                    <p>Please note that if you click on, or follow, any links from our site to an external site, our privacy policy will no longer apply. Please check the privacy policies of those sites before continuing to browse and submit any personal data to those sites. We do not accept any responsibility or liability in relation to them.</p>
                    <h2>Security and Retention of Data</h2>
                    <p>We are committed to ensuring that your information is secure.</p>
                    <p>For this purpose, we will not hold your personal data for any longer than is reasonable. Further, we have taken reasonable steps to protect your information against unauthorised access and against unlawful processing, accidental loss, damage and destruction. Nevertheless, any personal data submitted by you is at your own risk. Do not disclose any personal data that includes sensitive information such as your religion, philosophical view, sexuality etc. If you do submit any data of this nature then you are submitting it at your own risk entirely and we hold no responsibility for who views or where that information ends up. We cannot guarantee absolute security.</p>
                    <p>We will not transfer your personal details to any third party for the purpose of direct marketing without your permission. We use certain third-party service providers who may have access to your personal data so that they can provide services to us. When this occurs, we have a data protection compliant contract in place with these third parties. If you provided your details through our website then Moore Legal Technology who manages our online strategy will have access to your details.</p>
                    <h2>SAFE CUSTODY</h2>
                    <p>We charge an annual fee of £15 plus VAT for storing original documents in safe custody, e.g. Wills and title deeds. Charges vary depending on the volume of documents retained. We will notify you of our storage rates at the appropriate time.</p>
                    <h2>Call Tracking and Recording</h2>
                    <p>We use telephone tracking numbers to link a user’s call to the marketing channel that they originated from. This is done using cookies – you can choose to decline cookies, as explained above, if you would prefer not to be tracked.</p>
                    <h2>Downloads &amp; Media Files</h2>
                    <p>Any downloadable documents, files or media made available on this website are provided to users at their own risk. While all precautions have been undertaken to ensure only genuine downloads are available, users are advised to verify their authenticity using third party antivirus software or similar applications.</p>
                    <p>We accept no responsibility for third party downloads and downloads provided by external third-party websites and advise users to verify their authenticity using third party antivirus software or similar applications.</p>
                    <p>Anyone who is caught downloading, streaming or imposing any material that could cause harm to our users will be immediately suspend from the use of our website.</p>
                    <h2>Third Parties that we work with</h2>
                    <p><strong>Subcontractors</strong></p>
                    <p>From <a target="_blank" href="https://www.wilfordsmith.com/latest-news/working-time-time-on/">time to time we will ask outsource companies or people to provide services for us to ensure work</a> is done promptly and in the most cost-effective manner. We also will have out sourced audit companies to ensure we regulated. We will always have a confidentiality agreement with these outsourced providers.</p>
                    <p><strong>Other Third Parties</strong></p>
                    <p>There will be circumstances when we need to pass your personal information to others in order to progress your case and or to meet professional obligations.</p>
                    <h2>External Website Links &amp; Third Parties</h2>
                    <p>Our website may contain links to other websites of interest. However, once you have used these links to leave our site, you should note that we do not have any control over that other website. Therefore, we cannot be responsible for the protection and privacy of any information which you provide whilst visiting such sites and such sites are not governed by this privacy policy. Please be aware that we do not warn you when you have chosen to click through to another website or property when using our site. You should exercise caution and look at the appropriate privacy policy applicable to the relevant website being used.</p>
                    <h2>JOINT CONTROLLERS</h2>
                    <p>If we are also acting for your lender in a Conveyancing transaction, we have a duty to make full disclosure to the lender of all relevant facts relating to you, your purchase and mortgage.</p>
                    <h2>Social Media Usage</h2>
                    <p>While we may have official profiles on social media platforms, users are advised to verify authenticity of such profiles before engaging with, or sharing information with such profiles. We will never ask for user passwords or personal details on social media platforms. Users are advised to conduct themselves appropriately when engaging with us on social media.</p>
                    <p>There may be instances where our website features social sharing buttons, which help share web content directly from web pages to the respective social media platforms. You use social sharing buttons at your own discretion and accept that doing so may publish content to your social media profile feed or page. You can find further information about some social media privacy and usage policies in the resources section below.</p>
                    <h2>NOTICES AND REVISIONS</h2>
                    <p>We reserve the right to change our Privacy Policy at any time. When changes are made, they will take immediate effect unless stated otherwise. We encourage you refer to this policy on an ongoing basis to understand the current privacy practices. This privacy policy applies to all information we have about you, unless stated otherwise.</p>
                    <h2>Your personal information</h2>
                    <p>We will not sell, distribute or lease your personal information to third parties unless we have your permission or are required by law to do so.</p>
                    <h2>CHILDREN UNDER 13</h2>
                    <p>This site is not intended for use by anyone under the age of 13 nor does Wilford Smith knowingly collect or solicit personally identifiable information from anyone under the age of 13. If you are under 13, you may not attempt to send any information about yourself to us, including your name, address, telephone number or email address. In the event that we unknowingly collected information from someone under the age of 13 without parental consent, we will delete that information from our system immediately. If you are a parent or legal guardian of a child under 13 and believe that we may hold information of your child, please contact us at the email provided at the end of this policy.</p>
                    <h2>ENFORCEMENT</h2>
                    <p>We regularly review our compliance with this Privacy Policy. We will cooperate with the appropriate regulatory authorities, including local data protection authorities, to resolve any complaints regarding the transfer of personally identifiable information between Wilford Smith and an individual.</p>
                    <p>If you have any concerns about your privacy or would like any more information about your privacy please <a target="_blank" href="https://www.wilfordsmith.com/contact-us.html">contact us</a></p>
                    <p>If we respond to your complaint and you are not satisfied with the way we have tried to resolve the matter, you have the right to contact your local federal data protection authorities to lodge a complaint.</p>
                    <h2>ACCESS TO INFORMATION</h2>
                    <p><strong>Subject Access Request</strong></p>
                    <p>You have a right under DPA 2018 to access the information we hold about you. If you wish you see this information please contact us at…</p>
                    <p><strong>Right to be Forgotten</strong></p>
                    <p>You have the right under the DPA 2018 to request that all information which we hold about you be deleted entirely in line with our statutory and legal responsibilities. If you wish to exercise this right please contact us on…</p>
                    <p><strong>Right to Data Portability</strong></p>
                    <p>You have the right under the DPA to request a copy of all the information we hold about you. If you wish to exercise this right, please send your request to…”</p>
                    <h2>CYBER SECURITY</h2>
                    <p>We cannot absolutely guarantee the security of information communicated by email or mobile phone. Due to the current increase in cyber crime and fraud there is a risk that you may receive an email that appears to come from us (WS Strategic Ltd t/a Wliford Smith) but maybe a scam or spoof email. If, in the body of the email, indicates a change of our bank details previously supplied, do not reply or act on the email and contact us by telephone immediately.&nbsp; If you receive a suspicious email please contact us by TELEPHONE on 08081695677. Unless we hear from you on the contrary, we will assume that you consent for us to use these methods of communication.</p>
                    <p>Please be aware that we do not notify changes to important business information, such as bank account details, by email.</p>
                    <p>To prevent cybercrime, we suggest our clients use the Safe Buyer Scheme to check our bank details are correct before sending any funds to us – please visit <a target="_blank" href="https://www.safebuyerscheme.co.uk/">https://www.safebuyerscheme.co.uk/</a> and <a target="_blank" href="https://www.safebuyerscheme.co.uk/Home/ForThePublicSafeBuyer">https://www.safebuyerscheme.co.uk/Home/ForThePublicSafeBuyer</a></p>
                    <p>We cannot accept responsibility for funds paid into the wrong account.</p>
                    <h2>ELECTRONIC SIGNATURES</h2>
                    <p>We are permitted to rely on your electronic signature where given through docusign.co.uk or such other secure signature provider as used by us.</p>
                    <h2>Contact</h2>
                    <p>If you have any queries in relation to the above please do not hesitate to contact us through any of the contact methods on our site or on our admin email at <span id="cloakbcc035c6d27dca35b3944ca0bfb5722a"><a target="_blank" href="mailto:communications@wilfordsmith.com">communications@wilfordsmith.com</a></span>.</p>
                    <h2>Resources &amp; Further Information</h2>
                    <ul>
                    <li><a target="_blank" href="https://ico.org.uk/for-organisations/data-protection-reform/overview-of-the-gdpr/">Overview of the General Data Protection Regulation (GDPR)</a></li>
                    <li><a target="_blank" href="http://www.legislation.gov.uk/ukpga/1998/29/contents">Data Protection Act (DPA) 1998</a></li>
                    <li><a target="_blank" href="http://www.legislation.gov.uk/uksi/2003/2426/contents/made">Privacy and Electronic Communications Regulations (PECR) 2003</a></li>
                    <li><a target="_blank" href="https://ico.org.uk/for-organisations/guide-to-pecr/">The Guide to the PECR 2003</a></li>
                    <li><a target="_blank" href="http://twitter.com/privacy">Twitter Privacy Policy</a></li>
                    <li><a target="_blank" href="http://www.facebook.com/about/privacy/">Facebook Privacy Policy</a></li>
                    <li><a target="_blank" href="http://www.google.com/privacy.html">Google Privacy Policy</a></li>
                    <li><a target="_blank" href="http://www.linkedin.com/static?key=privacy_policy">LinkedIn Privacy Policy</a></li>
                    </ul>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ConsentModal;