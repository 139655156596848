import React from "react";
import { Fragment, useState } from "react";
import FormContainer from "./FormContainer";
import { useSearchParams } from "react-router-dom";

const Calculator = () => {
    const [searchParams] = useSearchParams();
    const [formType, setFormType] = useState('');
    const formTypeHandler = (e) => {
        setFormType(e.target.innerHTML);
    }
    const clearFormType = (e) => {
        setFormType('');
    }
    return (
        <Fragment>
            {searchParams.get('logo') ? (
                <img className="top_logo" src={searchParams.get('logo')} />
            ) :null}
            <div className="container overflow-hidden">
                {formType.length === 0 ? (
                    <Fragment>
                        <div className="row">
                            <div className="col home_btn" onClick={formTypeHandler}>Purchase</div>
                        </div>
                        <div className="row">
                            <div className="col home_btn" onClick={formTypeHandler}>Sale</div>
                        </div>
                        <div className="row">
                            <div className="col home_btn" onClick={formTypeHandler}>Purchase and Sale</div>
                        </div>
                        <div className="row">
                            <div className="col home_btn" onClick={formTypeHandler}>Remortgage</div>
                        </div>
                    </Fragment>
                ) : (
                    <Fragment>
                        <div className="row calc_form">
                            <FormContainer formType={formType} clearFormType={clearFormType} source={searchParams.get('source')} sourceLogo={searchParams.get('logo')} />
                        </div>
                    </Fragment>
                )}
            </div>
        </Fragment>
    )
}

export default Calculator;