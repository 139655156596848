import { Fragment, useEffect, useState } from "react";
import PurchaseQuote from "./Quote/PurchaseQuote";
import SaleQuote from "./Quote/SaleQuote";
import SalePurchaseQuote from "./Quote/SalePurchaseQuote";
import InstructQuote from "./instruct_quote";
import SaleForm from "./Form/SaleForm";
import PurchaseForm from "./Form/PurchaseForm";
import SalePurchaseForm from "./Form/SalePurchaseForm";
import ConsentModal from "./ConsentModal";

const FormContainer = (props) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [pdfCode, setPdfCode] = useState();

    const [saleFormData, setSaleFormData] = useState({
        enteredSalePrice: 0,
        gettingMortgage: 'No',
        mortgageLender: '',
        propertyHold: 'Freehold',
        propertyType: 'House',
        numberSellers: 0,
        purchaseSchemes: '',
        addressKnown: 'No',
        propertyAddressPostcode: ' ',
        bridgingFinance: 'No',
    });
    const [purchaseFormData, setPurchaseFormData] = useState({
        enteredPurchasePrice: 0,
        gettingMortgage: 'No',
        mortgageLender: '',
        propertyHold: 'Freehold',
        propertyType: 'House',
        propertyLocated: '',
        numberSellers: 0,
        purchaseSchemes: '',
        purchasingAdditional: 'No',
        gifted: 'No',
        firstTimeBuyer: 'No',
        propertyAddressPostcode: ' ',
        addressKnown: 'No',
        bridgingFinance: 'No',
    });
    const [aboutDetails, setAboutDetails] = useState({
        title: '',
        forename: '',
        surname: '',
        enteredPhone: '',
        enteredEmail: '',
        furtherInfo: '',
        usingCompany: 'No',
        companyDirectors: 1,
    });

    const [isFormSubmit, setIsFormSubmit] = useState(false);
    const [isInstruct, setIsInstruct] = useState(false);

    const pdfCodeHandler = (e) => {
        setPdfCode(e);
        console.log(pdfCode);
    }

    const formSubmitHandler = (event) => {
        if(props.formType === 'Sale' || props.formType === 'Remortgage') {
            setSaleFormData((formData) => {
                return {...formData, 
                    enteredSalePrice: event.target.salePrice.value,
                    mortgageLender: event.target.mortgageLender.value,
                    numberSellers: event.target.numberSeller.value,
                    purchaseSchemes: event.target.salePurchaseSchemes.value,
                }
            })
        } else if(props.formType === 'Purchase') {
            setPurchaseFormData((formData) => {
                return {...formData, 
                    enteredPurchasePrice: event.target.purchasePrice.value,
                    mortgageLender: event.target.mortgageLender.value,
                    propertyLocated: event.target.propertyLocated.value,
                    numberSellers: event.target.numberSeller.value,
                    purchaseSchemes: event.target.salePurchaseSchemes.value,
                }
            })
        } else if(props.formType === 'Purchase and Sale') {
            setSaleFormData((formData) => {
                return {...formData, 
                    enteredSalePrice: event.target.salePrice.value,
                    mortgageLender: event.target.sale_mortgageLender.value,
                    numberSellers: event.target.sale_numberSeller.value,
                    purchaseSchemes: event.target.sale_purchaseSchemes.value,
                }
            })
            setPurchaseFormData((formData) => {
                return {...formData, 
                    enteredPurchasePrice: event.target.purchasePrice.value,
                    mortgageLender: event.target.pur_mortgageLender.value,
                    propertyLocated: event.target.pur_propertyLocated.value,
                    numberSellers: event.target.pur_numberSeller.value,
                    purchaseSchemes: event.target.pur_purchaseSchemes.value,
                }
            })
        }
        if(aboutDetails.usingCompany === 'Yes' ? (
            setAboutDetails((formData) => {
                return {...formData,
                    title: event.target.contactTitle.value,
                    forename: event.target.contactForename.value,
                    surname: event.target.contactSurname.value,
                    enteredPhone: event.target.phone.value,
                    enteredEmail: event.target.email.value,
                    companyName: event.target.companyname.value,
                    companyAddress: event.target.companyaddress.value,
                    companyNumber: event.target.companynumber.value,
                }
            })
        ) : (
            setAboutDetails((formData) => {
                return {...formData,
                    title: event.target.contactTitle.value,
                    forename: event.target.contactForename.value,
                    surname: event.target.contactSurname.value,
                    enteredPhone: event.target.phone.value,
                    enteredEmail: event.target.email.value,
                }
            })
        ));
        setIsFormSubmit(true);
    }

    const directorNameHandler = (event, index) => {
        // let name = event.target.name
        let value = event.target.value
        setAboutDetails((formData) => {
            return {...formData,
                directorName: {
                    ...formData.directorName,
                    [index]: value,
                }
            }
        })
    }
    const directorPhoneHandler = (event, index) => {
        // let name = event.target.name
        let value = event.target.value
        setAboutDetails((formData) => {
            return {...formData,
                directorPhone: {
                    ...formData.directorPhone,
                    [index]: value,
                }
            }
        })
    }
    const directorEmailHandler = (event, index) => {
        // let name = event.target.name
        let value = event.target.value
        setAboutDetails((formData) => {
            return {...formData,
                directorEmail: {
                    ...formData.directorEmail,
                    [index]: value,
                }
            }
        })
    }
    
    const furtherInfoHandler = (e) => {
        setAboutDetails((formData) => {
            return {...formData,
                furtherInfo: e
            }
        })
    }

    const quoteBackHandler = () => {
        setIsFormSubmit(false);
        resetPurchaseAuctionState();
    }

    const haveMortgageHandler = (e) => {
        if(props.formType === 'Sale' || props.formType === 'Remortgage') {
            setSaleFormData((formData) => {
                return {...formData, gettingMortgage: e.target.value,}
            })
        } else if(props.formType === 'Purchase') {
            setPurchaseFormData((formData) => {
                return {...formData, gettingMortgage: e.target.value,}
            })
        } else if(props.formType === 'Purchase and Sale') {
            if(e.target.name === 'sale_have_mortgage') {
                setSaleFormData((formData) => {
                    return {...formData, gettingMortgage: e.target.value,}
                })
            } else if(e.target.name === 'pur_have_mortgage') {
                setPurchaseFormData((formData) => {
                    return {...formData, gettingMortgage: e.target.value,}
                })
            }
        }
    }
    const propertyHoldHandler = (e) => {
        if(props.formType === 'Sale' || props.formType === 'Remortgage') {
            setSaleFormData((formData) => {
                return {...formData, propertyHold: e.target.value,}
            })
        } else if(props.formType === 'Purchase') {
            setPurchaseFormData((formData) => {
                return {...formData, propertyHold: e.target.value,}
            })
        } else if(props.formType === 'Purchase and Sale') {
            if(e.target.name === 'sale_propertyHold') {
                setSaleFormData((formData) => {
                    return {...formData, propertyHold: e.target.value,}
                })
            } else if(e.target.name === 'pur_propertyHold') {
                setPurchaseFormData((formData) => {
                    return {...formData, propertyHold: e.target.value,}
                })
            }
        }
    }
    const propertyTypeHandler = (e) => {
        if(props.formType === 'Sale' || props.formType === 'Remortgage') {
            setSaleFormData((formData) => {
                return {...formData, propertyType: e.target.value,}
            })
        } else if(props.formType === 'Purchase') {
            setPurchaseFormData((formData) => {
                return {...formData, propertyType: e.target.value,}
            })
        } else if(props.formType === 'Purchase and Sale') {
            if(e.target.name === 'sale_propertyType') {
                setSaleFormData((formData) => {
                    return {...formData, propertyType: e.target.value,}
                })
            } else if(e.target.name === 'pur_propertyType') {
                setPurchaseFormData((formData) => {
                    return {...formData, propertyType: e.target.value,}
                })
            }
        }
    }
    
    const addressKnownHandler = (e) => {
        if(props.formType === 'Sale' || props.formType === 'Remortgage') {
            setSaleFormData((formData) => {
                return {...formData, addressKnown: e.target.value,}
            })
        } else if(props.formType === 'Purchase') {
            setPurchaseFormData((formData) => {
                return {...formData, addressKnown: e.target.value,}
            })
        } else if(props.formType === 'Purchase and Sale') {
            if(e.target.name === 'sale_addressKnown') {
                setSaleFormData((formData) => {
                    return {...formData, addressKnown: e.target.value,}
                })
            } else if(e.target.name === 'pur_addressKnown') {
                setPurchaseFormData((formData) => {
                    return {...formData, addressKnown: e.target.value,}
                })
            }
        }
    }

    const addressPostcodeHandler = (e) => {
        if(props.formType === 'Sale' || props.formType === 'Remortgage') {
            setSaleFormData((formData) => {
                return {...formData, propertyAddressPostcode: e.target.value,}
            })
        } else if(props.formType === 'Purchase') {
            setPurchaseFormData((formData) => {
                return {...formData, propertyAddressPostcode: e.target.value,}
            })
        } else if(props.formType === 'Purchase and Sale') {
            if(e.target.name === 'salePropertyAddressPostcode') {
                setSaleFormData((formData) => {
                    return {...formData, propertyAddressPostcode: e.target.value,}
                })
            } else if(e.target.name === 'purPropertyAddressPostcode') {
                setPurchaseFormData((formData) => {
                    return {...formData, propertyAddressPostcode: e.target.value,}
                })
            }
        }
    }

    const bridgingFinanceHandler = (e) => {
        if(props.formType === 'Remortgage' || props.formType === 'Sale') {
            setSaleFormData((formData) => {
                return {...formData, bridgingFinance: e.target.value,}
            })
        } else if(props.formType === 'Purchase') {
            setPurchaseFormData((formData) => {
                return {...formData, bridgingFinance: e.target.value,}
            })
        } else if(props.formType === 'Purchase and Sale') {
            if(e.target.name === 'saleBridgingFinance') {
                setSaleFormData((formData) => {
                    return {...formData, bridgingFinance: e.target.value,}
                })
            } else if(e.target.name === 'purchaseBridgingFinance')
                setPurchaseFormData((formData) => {
                    return {...formData, bridgingFinance: e.target.value,}
                })
        }
    }

    const usingCompanyHandler = (e) => {
        setAboutDetails((formData) => {
            return {...formData,
                usingCompany: e.target.value
            }
        })        
    }

    const directorIncrementHandler = (e) => {
        setAboutDetails((formData) => {
            return {...formData, companyDirectors: formData.companyDirectors+1,}
        })
    }

    const propertygiftedHandler = (e) => {
        setPurchaseFormData((formData) => {
            return {...formData, gifted: e.target.value,}
        })
    }
    const firstTimeHandler = (e) => {
        setPurchaseFormData((formData) => {
            return {...formData, firstTimeBuyer: e.target.value,}
        })
    }
    const purchaseAdditionalHandler = (e) => {
        setPurchaseFormData((formData) => {
            return {...formData, purchasingAdditional: e.target.value,}
        })
    }

    const instructClickHandler = (e) => {
        if(isInstruct === false) {
            setIsInstruct(true);
        } else {
            setIsInstruct(false);
        };
        setPdfCode(e);
    };
    const instructBackHandler = () => {
        setIsInstruct(false);
    };

    const clearFormType = (e) => {
        props.clearFormType();
    }
    
    const [purchaseAuctionInfo, setPurchaseAuctionInfo] = useState([
        {
            value: 'No',
            checked: false,
        },
        {
            value: 'Newbuild',
            checked: false,
        },
        {
            value: 'Unregistered',
            checked: false,
        },
        {
            value: 'Auction',
            checked: false,
        },
        {
            value: 'Buy to Let',
            checked: false,
        },
        {
            value: 'Repossession',
            checked: false,
        },
    ]);

    const [saleAuctionInfo, setSaleAuctionInfo] = useState([
        {
            value: 'Unregistered',
            checked: false,
        },
        {
            value: 'Auction',
            checked: false,
        },
        {
            value: 'Buy to Let',
            checked: false,
        },
        {
            value: 'Related to Probate',
            checked: false,
        },
        {
            value: 'Matrimonial',
            checked: false,
        },
    ]);
    const auctionChangeHandler = (e) => {
        if(props.formType === 'Sale') {
            const clonedAuction = [...saleAuctionInfo];
            setSaleAuctionInfo(
                clonedAuction.map((val) => (
                    val.value === e.target.value ? { ...val, checked: !val.checked }
                    : val
                ))
            );
        } else if(props.formType === 'Purchase') {
            const clonedAuction = [...purchaseAuctionInfo];
            setPurchaseAuctionInfo(
                clonedAuction.map((val) => (
                    val.value === e.target.value ? { ...val, checked: !val.checked }
                    : val
                ))
            );
        } else if(props.formType === 'Purchase and Sale') {
            if(e.target.name === 'sale_isAuction') {
                const clonedAuction = [...saleAuctionInfo];
                setSaleAuctionInfo(
                    clonedAuction.map((val) => (
                        val.value === e.target.value ? { ...val, checked: !val.checked }
                        : val
                    ))
                );
            } else if(e.target.name === 'pur_isAuction') {
                const clonedAuction = [...purchaseAuctionInfo];
                setPurchaseAuctionInfo(
                    clonedAuction.map((val) => (
                        val.value === e.target.value ? { ...val, checked: !val.checked }
                        : val
                    ))
                );
            }
        }
    };

    const resetPurchaseAuctionState = () => {
        setPurchaseAuctionInfo([
            {
                value: 'No',
                checked: false,
            },
            {
                value: 'Newbuild',
                checked: false,
            },
            {
                value: 'Unregistered',
                checked: false,
            },
            {
                value: 'Auction',
                checked: false,
            },
            {
                value: 'Buy to Let',
                checked: false,
            },
            {
                value: 'Repossession',
                checked: false,
            },
        ]);
    }
    return (
        <Fragment>
            {isFormSubmit === false ? (
                <Fragment>
                    <div className="mt-3 mb-3 page-title">
                        <div className="col form_back" onClick={clearFormType}>Back</div>
                    </div>
                    {props.formType === 'Purchase' ? (
                        <PurchaseForm aboutDetails={aboutDetails} formData={purchaseFormData} formSubmitHandler={formSubmitHandler} haveMortgageHandler={haveMortgageHandler} propertyHoldHandler={propertyHoldHandler} propertyTypeHandler={propertyTypeHandler} propertygiftedHandler={propertygiftedHandler} firstTimeHandler={firstTimeHandler} addressKnownHandler={addressKnownHandler} bridgingFinanceHandler={bridgingFinanceHandler} purchaseAdditionalHandler={purchaseAdditionalHandler} auctionChangeHandler={auctionChangeHandler} usingCompanyHandler={usingCompanyHandler} formType={props.formType} handleShow={handleShow} directorIncrementHandler={directorIncrementHandler} directorNameHandler={directorNameHandler} directorPhoneHandler={directorPhoneHandler} directorEmailHandler={directorEmailHandler} addressPostcodeHandler={addressPostcodeHandler} />
                    ) : props.formType === 'Purchase and Sale' ? (
                        <SalePurchaseForm aboutDetails={aboutDetails} saleFormData={saleFormData} purchaseFormData={purchaseFormData} formSubmitHandler={formSubmitHandler} haveMortgageHandler={haveMortgageHandler} propertyHoldHandler={propertyHoldHandler} propertyTypeHandler={propertyTypeHandler} addressKnownHandler={addressKnownHandler} bridgingFinanceHandler={bridgingFinanceHandler} propertygiftedHandler={propertygiftedHandler} firstTimeHandler={firstTimeHandler} purchaseAdditionalHandler={purchaseAdditionalHandler} auctionChangeHandler={auctionChangeHandler} usingCompanyHandler={usingCompanyHandler} formType={props.formType} handleShow={handleShow} directorIncrementHandler={directorIncrementHandler} directorNameHandler={directorNameHandler} directorPhoneHandler={directorPhoneHandler} directorEmailHandler={directorEmailHandler} addressPostcodeHandler={addressPostcodeHandler} />
                    ) : props.formType === 'Sale' || props.formType === 'Remortgage' ? (
                        <SaleForm aboutDetails={aboutDetails} auctionInfo={saleAuctionInfo} formData={saleFormData} formSubmitHandler={formSubmitHandler} haveMortgageHandler={haveMortgageHandler} propertyHoldHandler={propertyHoldHandler} propertyTypeHandler={propertyTypeHandler} propertygiftedHandler={propertygiftedHandler} firstTimeHandler={firstTimeHandler} purchaseAdditionalHandler={purchaseAdditionalHandler} addressKnownHandler={addressKnownHandler} bridgingFinanceHandler={bridgingFinanceHandler} auctionChangeHandler={auctionChangeHandler} usingCompanyHandler={usingCompanyHandler} formType={props.formType} handleShow={handleShow} directorIncrementHandler={directorIncrementHandler} directorNameHandler={directorNameHandler} directorPhoneHandler={directorPhoneHandler} directorEmailHandler={directorEmailHandler} addressPostcodeHandler={addressPostcodeHandler} />
                    ) : (
                        <div></div>
                    )}
                </Fragment>
            ) : isFormSubmit === true && isInstruct === false ? (
                <Fragment>
                    <div className="mt-3 mb-3 page-title">
                        <div className="col form_back" onClick={quoteBackHandler}>Back</div>
                    </div>
                {props.formType === 'Sale' ||  props.formType === 'Remortgage' ? (
                    <SaleQuote formData={saleFormData} auctionInfo={saleAuctionInfo} aboutDetails={aboutDetails} formType={props.formType} instructClickHandler={instructClickHandler} pdfCodeHandler={pdfCodeHandler} source={props.source}/>
                ) : props.formType === 'Purchase' ? (
                    <PurchaseQuote formData={purchaseFormData} aboutDetails={aboutDetails} auctionInfo={purchaseAuctionInfo} formType={props.formType} instructClickHandler={instructClickHandler} source={props.source}/>
                ) : props.formType === 'Purchase and Sale' ? (
                    <SalePurchaseQuote saleFormData={saleFormData} purchaseFormData={purchaseFormData} aboutDetails={aboutDetails} purchaseAuctionInfo={purchaseAuctionInfo} saleAuctionInfo={saleAuctionInfo} formType={props.formType} instructClickHandler={instructClickHandler} source={props.source}/>
                ) :null (
                )}
                </Fragment>
            ) : isInstruct === true ? (
                props.formType === 'Sale' ||  props.formType === 'Remortgage' ? (
                    <InstructQuote instructClickHandler={instructClickHandler} aboutDetails={aboutDetails} formType={props.formType} handleShow={handleShow} instructBackHandler={instructBackHandler} saleFormData={saleFormData} saleAuctionInfo={saleAuctionInfo} furtherInfoHandler={furtherInfoHandler} pdfCode={pdfCode} source={props.source} sourceLogo={props.sourceLogo} />
                ) : props.formType === 'Purchase' ? (
                    <InstructQuote instructClickHandler={instructClickHandler} aboutDetails={aboutDetails} formType={props.formType} handleShow={handleShow} instructBackHandler={instructBackHandler} purchaseFormData={purchaseFormData} purchaseAuctionInfo={purchaseAuctionInfo} furtherInfoHandler={furtherInfoHandler} pdfCode={pdfCode} source={props.source} sourceLogo={props.sourceLogo} />
                ) : props.formType === 'Purchase and Sale' ? (
                    <InstructQuote instructClickHandler={instructClickHandler} aboutDetails={aboutDetails} formType={props.formType} handleShow={handleShow} instructBackHandler={instructBackHandler} saleFormData={saleFormData} purchaseFormData={purchaseFormData} purchaseAuctionInfo={purchaseAuctionInfo} saleAuctionInfo={saleAuctionInfo} furtherInfoHandler={furtherInfoHandler} pdfCode={pdfCode} source={props.source} sourceLogo={props.sourceLogo} />
                ) :null
            ) :null}

            {/* Consent popup */}
            <ConsentModal show={show} handleClose={handleClose} />
        </Fragment>
    )
}

export default FormContainer;