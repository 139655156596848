import { Fragment, useState } from "react";
import Postcoder from "react-address-lookup";
import emailjs from 'emailjs-com';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faEnvelope, faPhone, faLocationPin, faBuilding } from "@fortawesome/free-solid-svg-icons";
import db from "../firebase";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";

const InstructQuote = (props) => {
    const [step, setStep] = useState(1);
    const [corrAddrLine, setCorrAddrLine] = useState('');
    const [corrAddrCity, setCorrAddrCity] = useState('');
    const [corrAddrCounty, setCorrAddrCounty] = useState('');
    const [corrAddrPincode, setCorrAddrPincode] = useState('');
    let auctionInfo = '';
    let purchaseAuctionInfo = '';
    let emailJsTemplate = '';
    let formData = {};
    let userFormData = {};
    let directors = {};
    if(props.aboutDetails.usingCompany === 'Yes') {
        {[...Array(props.aboutDetails.companyDirectors)].map((elementInArray, index) => (
            directors[index] = {
                name: props.aboutDetails.directorName[parseInt(index+1)],
                email: props.aboutDetails.directorEmail[parseInt(index+1)],
                phone: props.aboutDetails.directorPhone[parseInt(index+1)],
            }
        )
        )};
    }
    if(props.formType === 'Sale' || props.formType === 'Remortgage') {
        emailJsTemplate = 'template_2mota4g';
        props.saleAuctionInfo.map((e)=> {
            if(e.checked === true) {
                auctionInfo = auctionInfo+e.value+', '
            }
        });
        formData = {
            to_name: 'Dapinder Singh',
            source: props.source,
            quoteType: props.formType,
            price: props.saleFormData.enteredSalePrice,
            haveMortgage: props.saleFormData.gettingMortgage,
            mortgageLender: props.saleFormData.mortgageLender,
            propertyHold: props.saleFormData.propertyHold,
            propertyType: props.saleFormData.propertyType,
            numberSellers: props.saleFormData.numberSellers,
            purchaseSchemes: props.saleFormData.purchaseSchemes,
            isAuction: auctionInfo,
            addressKnown: props.saleFormData.addressKnown,
            propertyAddressPostcode: props.saleFormData.propertyAddressPostcode,
            usingCompany: props.aboutDetails.usingCompany,
            name: props.aboutDetails.title+' '+props.aboutDetails.forename+' '+props.aboutDetails.surname,
            phone: props.aboutDetails.enteredPhone,
            email: props.aboutDetails.enteredEmail,
            furtherInfo: props.aboutDetails.furtherInfo,
            directors: JSON.stringify(directors),
            addressLine1: corrAddrLine,
            addressCity: corrAddrCity,
            addressCounty: corrAddrCounty,
            addressPincode: corrAddrPincode,
            variable_cld7r39: props.pdfCode
        }
    } else if(props.formType === 'Purchase') {
        emailJsTemplate = 'template_qakoirf';
        props.purchaseAuctionInfo.map((e)=> {
            if(e.checked === true) {
                auctionInfo = auctionInfo+e.value+', '
            }
        });
        formData = {
            to_name: 'Dapinder Singh',
            source: props.source,
            quoteType: props.formType,
            price: props.purchaseFormData.enteredPurchasePrice,
            haveMortgage: props.purchaseFormData.gettingMortgage,
            mortgageLender: props.purchaseFormData.mortgageLender,
            propertyHold: props.purchaseFormData.propertyHold,
            propertyType: props.purchaseFormData.propertyType,
            numberSellers: props.purchaseFormData.numberSellers,
            propertyLocated: props.purchaseFormData.propertyLocated,
            purchaseSchemes: props.purchaseFormData.purchaseSchemes,
            isAuction: auctionInfo,
            additionalProperty: props.purchaseFormData.additionalProperty,
            gifted: props.purchaseFormData.gifted,
            firstTimeBuyer: props.purchaseFormData.firstTimeBuyer,
            addressKnown: props.purchaseFormData.addressKnown,
            propertyAddressPostcode: props.purchaseFormData.propertyAddressPostcode,
            usingCompany: props.aboutDetails.usingCompany,
            bridgingFinance: props.purchaseFormData.bridgingFinance,
            name: props.aboutDetails.title+' '+props.aboutDetails.forename+' '+props.aboutDetails.surname,
            phone: props.aboutDetails.enteredPhone,
            email: props.aboutDetails.enteredEmail,
            furtherInfo: props.aboutDetails.furtherInfo,
            addressLine1: corrAddrLine,
            addressCity: corrAddrCity,
            addressCounty: corrAddrCounty,
            addressPincode: corrAddrPincode,
            variable_1i4875j: props.pdfCode
        }
    } else if(props.formType === 'Purchase and Sale') {
        emailJsTemplate = 'template_ly2nxne';
        props.saleAuctionInfo.map((e)=> {
            if(e.checked === true) {
                auctionInfo = auctionInfo+e.value+', '
            }
        });
        props.purchaseAuctionInfo.map((e)=> {
            if(e.checked === true) {
                purchaseAuctionInfo = purchaseAuctionInfo+e.value+', '
            }
        });
        formData = {
            to_name: 'Dapinder Singh',
            source: props.source,
            quoteType: props.formType,
            salePrice: props.saleFormData.enteredSalePrice,
            saleHaveMortgage: props.saleFormData.gettingMortgage,
            saleMortgageLender: props.saleFormData.mortgageLender,
            salePropertyHold: props.saleFormData.propertyHold,
            salePropertyType: props.saleFormData.propertyType,
            saleNumberSellers: props.saleFormData.numberSellers,
            salePurchaseSchemes: props.saleFormData.purchaseSchemes,
            saleIsAuction: auctionInfo,
            saleAddressKnown: props.saleFormData.addressKnown,
            salePropertyAddressPostcode: props.saleFormData.propertyAddressPostcode,
            purchasePrice: props.purchaseFormData.enteredPurchasePrice,
            purchaseHaveMortgage: props.purchaseFormData.gettingMortgage,
            purchaseMortgageLender: props.purchaseFormData.mortgageLender,
            purchasePropertyHold: props.purchaseFormData.propertyHold,
            purchasePropertyType: props.purchaseFormData.propertyType,
            purchaseNumberSellers: props.purchaseFormData.numberSellers,
            purchasePropertyLocated: props.purchaseFormData.propertyLocated,
            purchasePurchaseSchemes: props.purchaseFormData.purchaseSchemes,
            purchaseIsAuction: purchaseAuctionInfo,
            purchaseAdditionalProperty: props.purchaseFormData.purchasingAdditional,
            purchaseGifted: props.purchaseFormData.gifted,
            purchaseFirstTimeBuyer: props.purchaseFormData.firstTimeBuyer,
            purchaseAddressKnown: props.purchaseFormData.addressKnown,
            purchasePropertyAddressPostcode: props.purchaseFormData.propertyAddressPostcode,
            purchaseBridgingFinance: props.purchaseFormData.bridgingFinance,
            usingCompany: props.aboutDetails.usingCompany,
            name: props.aboutDetails.title+' '+props.aboutDetails.forename+' '+props.aboutDetails.surname,
            phone: props.aboutDetails.enteredPhone,
            email: props.aboutDetails.enteredEmail,
            furtherInfo: props.aboutDetails.furtherInfo,
            addressLine1: corrAddrLine,
            addressCity: corrAddrCity,
            addressCounty: corrAddrCounty,
            addressPincode: corrAddrPincode,
            variable_vp8np39: props.pdfCode
        }
    }
    const sendEmail = () => {
        userFormData = {
            email: props.aboutDetails.enteredEmail,
            name: props.aboutDetails.title+' '+props.aboutDetails.forename+' '+props.aboutDetails.surname,
            variable_o24o4zw: props.pdfCode
        }

        emailjs.send('service_rm2gqek', emailJsTemplate , formData, '5-96IJGiBn2ND4n1k')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });

          // User Email
        emailjs.send('service_rm2gqek', 'template_hn43twd' , userFormData, '5-96IJGiBn2ND4n1k')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
    }

    const quoteCollectionRef = collection(db, "quotes");
    //Save Data to Firebase DB
    const firebaseSave = () => {
        let quoteData = '';
        if(props.formType === 'Sale' || props.formType === 'Remortgage') {
            quoteData = {
                quoteType: props.formType,
                saleAuctionInfo: props.saleAuctionInfo,
                saleFormData: props.saleFormData,
                aboutDetails: props.aboutDetails,
                source: props.source,
                sourceLogo: props.sourceLogo,
                pdfCode: props.pdfCode,
                addressLine1: corrAddrLine,
                addressCity: corrAddrCity,
                addressCounty: corrAddrCounty,
                addressPincode: corrAddrPincode,
                createdAt: serverTimestamp(),
            };
        } else if(props.formType === 'Purchase') {
            quoteData = {
                quoteType: props.formType,
                purchaseAuctionInfo: props.purchaseAuctionInfo,
                purchaseFormData: props.purchaseFormData,
                aboutDetails: props.aboutDetails,
                source: props.source,
                sourceLogo: props.sourceLogo,
                pdfCode: props.pdfCode,
                addressLine1: corrAddrLine,
                addressCity: corrAddrCity,
                addressCounty: corrAddrCounty,
                addressPincode: corrAddrPincode,
                createdAt: serverTimestamp(),
            };
        } else if(props.formType === 'Purchase and Sale') {
            quoteData = {
                quoteType: props.formType,
                purchaseAuctionInfo: props.purchaseAuctionInfo,
                saleAuctionInfo: props.saleAuctionInfo,
                purchaseFormData: props.purchaseFormData,
                saleFormData: props.saleFormData,
                aboutDetails: props.aboutDetails,
                source: props.source,
                sourceLogo: props.sourceLogo,
                pdfCode: props.pdfCode,
                addressLine1: corrAddrLine,
                addressCity: corrAddrCity,
                addressCounty: corrAddrCounty,
                addressPincode: corrAddrPincode,
                createdAt: serverTimestamp(),
            };
        }
        const createQuote = async () => {
            await addDoc(quoteCollectionRef, quoteData);
        };
        createQuote();
    }
    
    const saveData = (e) => {
        e.preventDefault();
        if(props.aboutDetails.usingCompany === 'Yes') {
            furtherInfoHandler(e.target.furtherInfo.value);
        }
        if(corrAddrPincode !== '') {
            setStep(step + 1);
            sendEmail();
            firebaseSave();
        } else {
            alert('Please enter Pincode to continue.');
        }
    };

    const instructBackHandler = () => {
        props.instructBackHandler();
    };

    const furtherInfoHandler = (e) => {
        props.furtherInfoHandler(e);
    }

    // Address
    const corrAddress = (addr) => {
        setCorrAddrLine(addr['addressline1']);
        setCorrAddrCity(addr['posttown']);
        setCorrAddrCounty(addr['county']);
        setCorrAddrPincode(addr['postcode']);
    };

    const blankFunction = () => {
        return null;
    }
    return (
        <Fragment>
            <div className="mt-3 mb-3 page-title">
                <div className="col form_back" onClick={instructBackHandler}>Back</div>
            </div>
            {step === 1 ? (
                <Fragment>
                    <h1>Client Details</h1>
                    <form className="needs-validation pt-2" onSubmit={saveData}>
                        <div className="row input-group pt-3 media-1">
                            <div className="col-2 media-width">
                                <label for="name" className="text-start formLabel">Name</label>
                            </div>
                            <div className="col-10 media-width">
                                <div className="input-group">
                                    <select id="contactTitle" className="form-control" defaultValue={props.aboutDetails.title}>
                                        <option value="">Title...</option>
                                        <option value="Dr">Dr</option>
                                        <option value="Miss">Miss</option>
                                        <option value="Mr">Mr</option>
                                        <option value="Mrs">Mrs</option>
                                        <option value="Ms">Ms</option>
                                    </select>
                                    <input id="contactForename" type="text" placeholder="Forename" className="form-control" defaultValue={props.aboutDetails.forename} onChange={blankFunction} />
                                    <input id="contactSurname" type="text" placeholder="Surname" className="form-control" defaultValue={props.aboutDetails.surname}/>
                                </div>
                            </div>
                        </div>
        
                        <div className="row pt-3 media-1">
                            <div className="col-2 media-width">
                                <label for="phone" className="text-start formLabel">Phone*</label>
                            </div>
                            <div className="col-10 media-width">
                                <div class="input-group">
                                    <span class="input-group-text" id="basic-addon1"><FontAwesomeIcon icon={faPhone} /></span>
                                    <input type="tel" className="form-control" id="phone" defaultValue={props.aboutDetails.enteredPhone} required="required"/>
                                </div>
                            </div>
                        </div>
                        <div className="row pt-3 media-1">
                            <div className="col-2 media-width">
                                <label for="email" className="text-start formLabel">Email*</label>
                            </div>
                            <div className="col-10 media-width">
                                <div class="input-group">
                                    <span class="input-group-text" id="basic-addon1"><FontAwesomeIcon icon={faEnvelope} /></span>
                                    <input type="email" className="form-control"  id="email" defaultValue={props.aboutDetails.enteredEmail} required="required" />
                                </div>
                            </div>
                        </div>
                        {props.aboutDetails.usingCompany === 'Yes' ? (
                            <div className="row pt-3 media-1">
                                <div className="col-2 media-width">
                                    <label for="email" className="text-start formLabel">Further Information</label>
                                </div>
                                <div className="col-10 media-width">
                                    <div class="input-group">
                                        <textarea className="form-control"  id="furtherInfo" defaultValue={props.aboutDetails.furtherInfo} placeholder="Please include any further relevant information including who the main point of contact will be for this transaction" />
                                    </div>
                                </div>
                            </div>
                        ) :null}
                        <div className="pt-5">
                            <h4>CORRESPONDENCE ADDRESS</h4>
                        </div>
                        <div className="row pt-2 media-1">
                            <div className="col-2 media-width">
                                <label for="county" className="text-start formLabel">Postcode*</label>
                            </div>
                            <div className="col-10 text-end media-width">
                                <div class="input-group mb-3">
                                    <Postcoder apiKey="PCW82-S7Z5Y-JHEKC-MUYA3" addressSelectedCallback={corrAddress}/>
                                </div>
                            </div>
                        </div>
                        {corrAddrLine !== '' ? (
                            <Fragment>
                                <div className="row pt-3 media-1">
                                    <div className="col-2 media-width">
                                        <label for="text" className="text-start formLabel">Line 1*</label>
                                    </div>
                                    <div className="col-10 media-width">
                                        <input type="text" id="line_1" className="form-control" value={corrAddrLine} />
                                    </div>
                                </div>
                                <div className="row pt-1 media-1">
                                    <div className="col-2 media-width">
                                    </div>
                                    <div className="col-10 media-width">
                                        <input type="text" id="line_2" className="form-control" />
                                    </div>
                                </div>
                                <div className="row pt-1 media-1">
                                    <div className="col-2 media-width">
                
                                    </div>
                                    <div className="col-10 media-width">
                                        <input type="text" id="line_3" className="form-control" />
                                    </div>
                                </div>
                                <div className="row pt-2 media-1">
                                    <div className="col-2 media-width">
                                        <label for="city" className="text-start formLabel">City*</label>
                                    </div>
                                    <div className="col-10 media-width">
                                        <input type="text" placeholder="City/Town" className="form-control" id="city" value={corrAddrCity}/>
                                    </div>
                                </div>
                                <div className="row pt-2 media-1">
                                    <div className="col-2 media-width">
                                        <label for="county" className="text-start formLabel">County</label>
                                    </div>
                                    <div className="col-10 media-width">
                                        <input type="text" placeholder="County" className="form-control" id="county" value={corrAddrCounty} />
                                    </div>
                                </div>
                            </Fragment>
                        ) :null }
                        <div className="line_break"></div>
                        <div className="row consent">
                            <div className="col">
                                <input className="form-check-input" type="checkbox" name="consent" id="isConsent" required="required" value="1" />
                                <label className="form-check-label d-inline px-2" for="isConsent">
                                    I consent to my personal information being processed by Wilford Smith and necessary third parties approved by Wilford Smith as detailed in their <span className="privacy_toggle" onClick={props.handleShow}>privacy statement</span>
                                </label>
                            </div>
                        </div>
                        <div className="quote_actions">
                            <button type="submit" className="btn btn-success">Proceed</button>
                        </div>
                    </form>
                </Fragment>
            ) : step === 2 ? (
                <Fragment>
                    <div className="INSTRUCT_section">
                        <div className="row pb-4 pt-3">
                            <h1>Submission Successful</h1>
                        </div>

                        <div className="details">
                            <div className="details-heading">
                                <h4>Your Details</h4>
                            </div>
                            <div className="details-body">
                                <div className="details-body-content">
                                    <FontAwesomeIcon icon={faUser} />
                                    <br />
                                    {props.aboutDetails.title} {props.aboutDetails.forename} {props.aboutDetails.surname}
                                </div>
                                <div className="details-body-content">
                                <FontAwesomeIcon icon={faEnvelope} />
                                    <br />
                                    {props.aboutDetails.enteredEmail}
                                </div>
                            </div>
                            <div className="details-body">
                                <div className="details-body-content p-3">
                                    <FontAwesomeIcon icon={faPhone} />
                                    <br />
                                    {props.aboutDetails.enteredPhone}
                                </div>
                                <div className="details-body-content p-3">
                                    <FontAwesomeIcon icon={faLocationPin} />
                                    <br />
                                    {corrAddrLine}
                                    <br />
                                    {corrAddrCity}
                                    <br />
                                    {corrAddrPincode}
                                </div>
                            </div>
                        </div>
                        <div className="details">
                            {props.aboutDetails.usingCompany === 'Yes' ? (
                                <Fragment>
                                    <div className="details-heading">
                                        <h4>Company Details</h4>
                                    </div>
                                    <div className="details-body">
                                        <div className="details-body-content">
                                            <FontAwesomeIcon icon={faBuilding} />
                                            <br />
                                            {props.aboutDetails.companyName}
                                        </div>
                                        <div className="details-body-content">
                                            <FontAwesomeIcon icon={faLocationPin} />
                                            <br />
                                            {props.aboutDetails.companyAddress}
                                        </div>
                                        <div className="details-body-content">
                                        <FontAwesomeIcon icon={faPhone} />
                                            <br />
                                            {props.aboutDetails.companyNumber}
                                        </div>
                                    </div>
                                </Fragment>
                            ) :null}
                        </div>
                        {props.aboutDetails.usingCompany === 'Yes' ? (
                            <div className="details">
                                <div className="details-heading">
                                    <h4>Director Details</h4>
                                    {[...Array(props.aboutDetails.companyDirectors)].map((elementInArray, index) => (
                                        <Fragment>
                                            <br></br>
                                            <h6>Director {parseInt(index+1)}</h6>
                                            <div className="details-body">
                                                <div className="details-body-content">
                                                    <FontAwesomeIcon icon={faUser} />
                                                    <br></br>
                                                    {props.aboutDetails.directorName[parseInt(index+1)]}
                                                </div>
                                                <div className="details-body-content">
                                                    <FontAwesomeIcon icon={faPhone} />
                                                    <br></br>
                                                    {props.aboutDetails.directorPhone[parseInt(index+1)]}
                                                </div>
                                                <div className="details-body-content">
                                                    <FontAwesomeIcon icon={faEnvelope} />
                                                    <br></br>
                                                    {props.aboutDetails.directorEmail[parseInt(index+1)]}
                                                </div>
                                            </div>
                                        </Fragment>
                                    )
                                    )}
                                </div>
                            </div>
                        ) :null}
                    </div>
                </Fragment>
            ) : (
                <div></div>
            )}
        </Fragment>
    )
}

export default InstructQuote;