import { useEffect, useState } from "react";
import { BlobProvider, Document, Page, Image, Text, View, StyleSheet, PDFDownloadLink, Link } from '@react-pdf/renderer';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import db from "../../firebase";
import { collection, getDocs } from "firebase/firestore";

const SaleQuote = (props) => {
    const enteredPrice = props.formData.enteredSalePrice;
    const formType = props.formType;
    const [legalFee, setLegalFee] = useState(0);
    const [idCheck, setIdCheck] = useState(0);
    const [lawyerChecker, setLawyerChecker] = useState(0);
    const [ttFee, setTtFee] = useState(0);
    const [landSearch, setLandSearch] = useState(0);
    const [leaseholdFlat, setLeaseholdFlat] = useState(0);
    const [leaseholdHouse, setLeaseholdHouse] = useState(0);
    const [sharedOwner, setSharedOwner] = useState(0);
    const [auctionSalePur, setAuctionSalePur] = useState(0);
    const [bridgingFinance, setBridgingFinance] = useState(0);
    const [companiesHouse, SetCompaniesHouse] = useState(0);
    const [companiesHouseCharge, SetCompaniesHouseCharge] = useState(0);
    const [landSearchCompany, setLandSearchCompany] = useState(0);
    const [totalQuote, setTotalQuote] = useState(0);
    const [discount, setDiscount] = useState(0);
    const fixPrice = 500;
    const [loading, setLoading] = useState(false);
    const [prices, setPrices] = useState(false);

    // Get Prices
    const getPrices = async () => {
        setLoading(true);
        const pricesCollectionRef = collection(db, "prices");
        await getDocs(pricesCollectionRef)
        .then((querySnapshot)=>{
            const pricesData = querySnapshot.docs
               .map((doc) => ({...doc.data(), id:doc.id }));
            setPrices(pricesData);
        })
    }
    
    let pdfbase = '';
    //Instruct Click
    const instructClickHandler = () => {
        props.instructClickHandler(pdfbase);
    };
    
    const feeCalculations = () => {
        if(prices.length > 0) {
            setIdCheck(parseInt(prices[1].idCheck) * props.formData.numberSellers);
            setLawyerChecker(parseInt(prices[1].lawyerChecker));
            setTtFee(parseInt(prices[1].ttFee));
            setLandSearch(parseInt(prices[1].landSearch));
            if(props.formType === 'Sale' && props.source === 'Mortgage Advice UK / Whitehorns') {
                setDiscount(100);
            }
            if(props.formData.propertyType === 'Flat' && props.formData.propertyHold === 'Leasehold') {
                if(formType === 'Sale') {
                    setLeaseholdFlat(parseInt(prices[1].leaseholdFlat));
                } else if(formType === "Remortgage") {
                    setLeaseholdFlat(parseInt(prices[1].leaseholdFlatRemortgage));
                }
            } else if(props.formData.propertyType === 'House' && props.formData.propertyHold === 'Leasehold') {
                setLeaseholdHouse(parseInt(prices[1].leaseholdHouse));
            }
            if(props.auctionInfo[1].checked === true) {
                setAuctionSalePur(parseInt(prices[1].auctionSalePur));
            }
            if(props.formData.purchaseSchemes === 'Shared Ownership Scheme') {
                setSharedOwner(parseInt(prices[1].sharedOwner));
            }
            if(props.formData.bridgingFinance === 'Yes') {
                setBridgingFinance(parseInt(prices[1].bridgingFinance));
            }
            if(props.aboutDetails.usingCompany === 'Yes' && props.formData.gettingMortgage === 'Yes') {
                SetCompaniesHouse(parseInt(prices[1].companiesHouse));
                SetCompaniesHouseCharge(parseInt(prices[1].companiesHouseCharge));
                setLandSearchCompany(parseInt(prices[1].landSearchCompany));
            }
            //Base Fee Calculation
            if(formType === 'Sale') {
                if(enteredPrice < 150001) {
                    setLegalFee(parseInt(prices[1].legalFee1));
                } else if(enteredPrice > 150000 && enteredPrice < 250001) {
                    setLegalFee(parseInt(prices[1].legalFee2));
                } else if(enteredPrice > 250000 && enteredPrice < 500001) {
                    setLegalFee(parseInt(prices[1].legalFee3));
                } else if(enteredPrice > 500000 && enteredPrice < 750001) {
                    setLegalFee(parseInt(prices[1].legalFee4));
                } else if(enteredPrice > 750000 && enteredPrice < 1000001) {
                    setLegalFee(parseInt(prices[1].legalFee5));
                } else if(enteredPrice > 1000000 && enteredPrice < 2000001) {
                    setLegalFee(parseInt(prices[1].legalFee6));
                } else if(enteredPrice > 2000000) {
                    setLegalFee(parseInt(Number(enteredPrice) * 0.10 / 100) );
                } 
            } else if(formType === 'Remortgage') {
                if(enteredPrice < 150001) {
                    setLegalFee(parseInt(prices[1].legalFeeR1));
                } else if(enteredPrice > 150000 && enteredPrice < 250001) {
                    setLegalFee(parseInt(prices[1].legalFeeR2));
                } else if(enteredPrice > 250000 && enteredPrice < 500001) {
                    setLegalFee(parseInt(prices[1].legalFeeR3));
                } else if(enteredPrice > 500000 && enteredPrice < 750001) {
                    setLegalFee(parseInt(prices[1].legalFeeR4));
                } else if(enteredPrice > 750000 && enteredPrice < 1000001) {
                    setLegalFee(parseInt(prices[1].legalFeeR5));
                } else if(enteredPrice > 1000000 && enteredPrice < 2000001) {
                    setLegalFee(parseInt(prices[1].legalFeeR6));
                } else if(enteredPrice > 2000000) {
                    setLegalFee(parseInt(prices[1].legalFeeR7));
                }
            }
            // Total Quote
            // if(props.formType === 'Remortgage' && props.source === 'Mortgage Advice UK / Whitehorns') {
            //     setTotalQuote(parseInt(fixPrice));
            // } else {
            //     setTotalQuote(parseInt(legalFee + idCheck + lawyerChecker + ttFee + leaseholdFlat + leaseholdHouse + sharedOwner + auctionSalePur + bridgingFinance + companiesHouse + landSearch + landSearchCompany));
            // }
            // setLoading(false);
        }
    };

    const calculateTotal = () => {
        if(legalFee > 0) {
            if(props.formType === 'Remortgage' && props.source === 'Mortgage Advice UK / Whitehorns') {
                setTotalQuote(parseInt(fixPrice));
            } else {
                setTotalQuote(parseInt(legalFee + idCheck + lawyerChecker + ttFee + leaseholdFlat + leaseholdHouse + sharedOwner + auctionSalePur + bridgingFinance + companiesHouse + landSearch + landSearchCompany));
            }
            setLoading(false);
        }
    }

    useEffect(() => {
            // feeCalculations();
            getPrices();
    }, []);

    useEffect(() => {
        feeCalculations();
    }, [prices]);

    useEffect(() => {
        calculateTotal();
    }, [legalFee]);

    // Create styles
    const styles = StyleSheet.create({
        page: {
            backgroundColor: "#fff",
            color: "#000",
            padding: 50,
        },
        title: {
            fontSize: 16,
            padding:10,
            textDecoration: "underline",
            width: '100%',
            textAlign: 'center',
        },
        text: {
            fontSize: 12,
            padding:10,
            textAlign: 'left',
            display: 'flex',
            alignContent: 'center',
        },
        section: {
            margin: 10,
            padding: 10,
        },
        viewer: {
            width: window.innerWidth,
            height: window.innerHeight,
        },
        table: { 
            display: "table", 
            width: "auto", 
            borderStyle: "solid", 
            borderWidth: 1, 
            borderRightWidth: 1, 
            borderBottomWidth: 1,
            borderTopWidth: 1,
            borderLeftWidth: 1,
            width: "80%"
        }, 
        tableRow: { 
            margin: "auto", 
            flexDirection: "row",
        },
        tableRowFirstLine: { 
            margin: "auto", 
            flexDirection: "row",
            borderStyle: "solid", 
            borderWidth: 1, 
            borderLeftWidth: 0, 
            borderRightWidth: 0, 
            borderBottomWidth: 0, 
            borderTopWidth: 1,
            backgroundColor: '#000',
            color: '#fff',
        },
        tableColFullWidth: { 
            width: '100%',
            margin: "0", 
            flexDirection: "row",
            borderStyle: "solid", 
            borderWidth: 1, 
            borderLeftWidth: 0, 
            borderRightWidth: 0, 
            borderBottomWidth: 0, 
            borderTopWidth: 1,
            backgroundColor: '#000',
            color: '#fff',
            backgroundColor: '#333333',
        },
        tableColfirst: { 
            width: "50%", 
            borderStyle: "solid", 
            borderWidth: 1, 
            borderLeftWidth: 1, 
            borderTopWidth: 0 ,
        },
        tableColfirstDark: {
            width: "50%", 
            borderStyle: "solid", 
            borderWidth: 1, 
            borderLeftWidth: 1, 
            borderTopWidth: 0 ,
            color: '#fff',
            backgroundColor: '#333333',
        },
        tableColGreyFirst: {
            width: "50%", 
            borderStyle: "solid", 
            borderWidth: 1, 
            borderLeftWidth: 1, 
            borderTopWidth: 0 ,
            color: '#fff',
            backgroundColor: '#cccccc',
        },
        tableColGrey: {
            width: "25%", 
            borderStyle: "solid", 
            borderWidth: 1, 
            borderLeftWidth: 1, 
            borderTopWidth: 0 ,
            color: '#fff',
            backgroundColor: '#cccccc',
        },
        tableCol: { 
            width: "25%", 
            borderStyle: "solid", 
            borderWidth: 1, 
            borderLeftWidth: 0, 
            borderTopWidth: 0 
        },
        tableCol50: {
            width: "50%", 
            borderStyle: "solid", 
            borderWidth: 1, 
            borderLeftWidth: 0, 
            borderTopWidth: 0 
        },
        tableCell: { 
            margin: "auto", 
            marginTop: 5, 
            fontSize: 10 
        },
        container: {
            padding: 10,
        },
        logo: {
            width: 200,
            padding: 10,
        }
    });
    const MyDoc = () => (
        <Document>
          <Page size="A4" style={styles.page}>
            <View style="styles.section">
                <Image src="/images/logo.png" style={styles.logo} />
                <Text style={styles.title}>Conveyancing Estimate</Text>
                <Text style={styles.text}>Thank you for your interest in our legal services. Please find below an Estimate for fees and disbursements in connection with your transaction at £{parseInt(totalQuote - discount) + parseInt(totalQuote - discount)*20/100 }</Text>
                <View style={styles.container}>
                    <View style={styles.tableRowFirstLine}>
                        <View style={styles.tableColfirst}>
                            <Text style={styles.tableCell}>Service</Text> 
                        </View> 
                        <View style={styles.tableCol}> 
                            <Text style={styles.tableCell}>Fee Exc. Vat</Text> 
                        </View> 
                        <View style={styles.tableCol}> 
                            <Text style={styles.tableCell}>Vat</Text> 
                        </View> 
                    </View>
                    <View style={styles.tableRowFirstLine}>
                        <View style={styles.tableColFullWidth}>
                            <Text style={styles.tableCell}>Legal fee</Text> 
                        </View> 
                    </View>
                    <View style={styles.tableRow}> 
                        <View style={styles.tableColfirst}> 
                            <Text style={styles.tableCell}>Legal Fee</Text> 
                        </View> 
                        <View style={styles.tableCol}> 
                            <Text style={styles.tableCell}>£{legalFee}</Text> 
                        </View> 
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>£{legalFee * 20 /100}</Text> 
                        </View>
                    </View>
                    <View style={styles.tableRowFirstLine}>
                        <View style={styles.tableColFullWidth}>
                            <Text style={styles.tableCell}>Additional costs added to base legal fee</Text> 
                        </View> 
                    </View>
                    <View style={styles.tableRow}> 
                        <View style={styles.tableColfirst}> 
                            <Text style={styles.tableCell}>ID Check</Text> 
                        </View> 
                        <View style={styles.tableCol}> 
                            <Text style={styles.tableCell}>£{idCheck}</Text> 
                        </View> 
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>£{idCheck * 20 /100}</Text> 
                        </View>
                    </View>
                    <View style={styles.tableRow}> 
                        <View style={styles.tableColfirst}> 
                            <Text style={styles.tableCell}>Lawyer Checker Fee</Text> 
                        </View> 
                        <View style={styles.tableCol}> 
                            <Text style={styles.tableCell}>£{lawyerChecker}</Text> 
                        </View> 
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>£{lawyerChecker * 20 /100}</Text> 
                        </View>
                    </View>
                    <View style={styles.tableRow}> 
                        <View style={styles.tableColfirst}> 
                            <Text style={styles.tableCell}>TT Fee</Text> 
                        </View> 
                        <View style={styles.tableCol}> 
                            <Text style={styles.tableCell}>£{ttFee}</Text> 
                        </View> 
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>£{ttFee * 20 /100}</Text> 
                        </View>
                    </View>
                    {/* {formType === 'Sale' ? (
                        <View style={styles.tableRow}> 
                            <View style={styles.tableColfirst}> 
                                <Text style={styles.tableCell}>Stamp Duty Land Tax Form</Text> 
                            </View> 
                            <View style={styles.tableCol}> 
                                <Text style={styles.tableCell}>£{stampDutyLandTax}</Text> 
                            </View> 
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>£{stampDutyLandTax * 20 /100}</Text> 
                            </View>
                        </View>
                    ) :null} */}
                    {props.formData.propertyType === 'Flat' && props.formData.propertyHold === 'Leasehold' ? (
                            <View style={styles.tableRow}> 
                                <View style={styles.tableColfirst}> 
                                    <Text style={styles.tableCell}>Leasehold flat/apartment</Text> 
                                </View> 
                                <View style={styles.tableCol}> 
                                    <Text style={styles.tableCell}>£{leaseholdFlat}</Text> 
                                </View> 
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>£{leaseholdFlat * 20 /100}</Text> 
                                </View>
                            </View>
                        ) : props.formData.propertyType === 'House' && props.formData.propertyHold === 'Leasehold' ? (
                            <View style={styles.tableRow}> 
                                <View style={styles.tableColfirst}> 
                                    <Text style={styles.tableCell}>Leasehold house</Text> 
                                </View> 
                                <View style={styles.tableCol}> 
                                    <Text style={styles.tableCell}>£{leaseholdHouse}</Text> 
                                </View> 
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>£{leaseholdHouse * 20 /100}</Text> 
                                </View>
                            </View>
                        ) :null}
                        {sharedOwner > 0 ? (
                            <View style={styles.tableRow}> 
                                <View style={styles.tableColfirst}> 
                                    <Text style={styles.tableCell}>Shared ownership</Text> 
                                </View> 
                                <View style={styles.tableCol}> 
                                    <Text style={styles.tableCell}>£{sharedOwner}</Text> 
                                </View> 
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>£{sharedOwner * 20 /100}</Text> 
                                </View>
                            </View>
                        ) :null}
                        {props.auctionInfo[1].checked === true && (
                            <View style={styles.tableRow}> 
                                <View style={styles.tableColfirst}> 
                                    <Text style={styles.tableCell}>Auction sale / purchase</Text> 
                                </View> 
                                <View style={styles.tableCol}> 
                                    <Text style={styles.tableCell}>£{auctionSalePur}</Text> 
                                </View> 
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>£{auctionSalePur * 20 /100}</Text> 
                                </View>
                            </View>
                        )}
                        {bridgingFinance > 0 ? (
                            <View style={styles.tableRow}> 
                                <View style={styles.tableColfirst}> 
                                    <Text style={styles.tableCell}>Bridging finance</Text> 
                                </View> 
                                <View style={styles.tableCol}> 
                                    <Text style={styles.tableCell}>£{bridgingFinance}</Text> 
                                </View> 
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>£{bridgingFinance * 20 /100}</Text> 
                                </View>
                            </View>
                        ) :null }
                        {companiesHouse > 0 ?(
                            <View style={styles.tableRow}> 
                                <View style={styles.tableColfirst}> 
                                    <Text style={styles.tableCell}>Registering a charge at Companies House</Text> 
                                </View> 
                                <View style={styles.tableCol}> 
                                    <Text style={styles.tableCell}>£{companiesHouse}</Text> 
                                </View> 
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>£{companiesHouse * 20 /100}</Text> 
                                </View>
                            </View>
                        ) :null}
                        {companiesHouseCharge > 0 ?(
                            <View style={styles.tableRow}> 
                                <View style={styles.tableColfirst}> 
                                    <Text style={styles.tableCell}>Companies House charge (per charge)</Text> 
                                </View> 
                                <View style={styles.tableCol}> 
                                    <Text style={styles.tableCell}>£{companiesHouseCharge}</Text> 
                                </View> 
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>£{companiesHouseCharge * 20 /100}</Text> 
                                </View>
                            </View>
                        ) :null}
                        {landSearchCompany > 0 ? (  
                            <View style={styles.tableRow}> 
                                <View style={styles.tableColfirst}> 
                                    <Text style={styles.tableCell}>Land Registry & Bankruptcy searches (company)</Text> 
                                </View> 
                                <View style={styles.tableCol}> 
                                    <Text style={styles.tableCell}>£{landSearchCompany}</Text> 
                                </View> 
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>£{landSearchCompany * 20 /100}</Text> 
                                </View>
                            </View>
                        ) :null}
                        <View style={styles.tableRow}> 
                            <View style={styles.tableColfirst}> 
                                <Text style={styles.tableCell}>Land Registry Documents</Text> 
                            </View> 
                            <View style={styles.tableCol}> 
                                <Text style={styles.tableCell}>£{landSearch}</Text> 
                            </View> 
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>£{landSearch * 20 /100}</Text> 
                            </View>
                        </View>
                        {discount > 0 ? (
                            <View style={styles.tableRow}> 
                                <View style={styles.tableColGreyFirst}> 
                                    <Text style={styles.tableCell}>Discount</Text> 
                                </View> 
                                <View style={styles.tableColGrey}> 
                                    <Text style={styles.tableCell}>£{discount}</Text> 
                                </View> 
                                <View style={styles.tableColGrey}>
                                    <Text style={styles.tableCell}>£{discount * 20 /100}</Text> 
                                </View>
                            </View>
                        ) :null}
                        <View style={styles.tableRow}> 
                            <View style={styles.tableColfirstDark}> 
                                {props.formType === 'Remortgage' && props.source === 'Mortgage Advice UK / Whitehorns' ? (
                                    <Text style={styles.tableCell}>Total Discounted quote (Including VAT)</Text> 
                                ) : (
                                    <Text style={styles.tableCell}>Total costs including VAT and disbursements</Text> 
                                )}  
                            </View> 
                            <View style={styles.tableColfirstDark}>
                                <Text style={styles.tableCell}>£{parseInt(totalQuote - discount) + parseInt(totalQuote - discount)*20/100}</Text> 
                            </View>
                        </View>
                    </View>
                    <Text style={styles.text}>
                        At Wilford Smith Solicitors, we pride ourselves on customer service. We will only ever be at the end of the phone or email if you ever have any queries.
                    </Text>
                    <Text style={styles.text}>
                        If you would like to instruct us to proceed, please reply by return of email or call us on 0808 1695677:
                    </Text>
                    <Text style={styles.text}>
                        ** The fees and disbursement, where applicable, are inclusive of VAT at current rates and that should the VAT 
                        rate change the estimate will be subject to change. 
                    </Text>
                    <Text style={styles.text}>
                        The estimate is based on the information available at the time the estimate was given but additional charges and disbursements, and VAT where appropriate, may apply depending on the nature of the transaction.
                    </Text>
                    <Text style={styles.text}>
                        Subject to the above this estimate will be valid until the expiry of 28 days the starting date for which will be the day after the estimate was given.
                    </Text>
            </View>
          </Page>
        </Document>
      );

    return (
        <div>
            <div className="display-6 text-start">Your Estimate</div>
            <div className="quote_big">
                {discount > 0 ? (
                    "£"+Math.round(parseInt(totalQuote-discount) + parseInt(totalQuote-discount)*20/100)
                ) : (
                    "£"+Math.round(totalQuote + totalQuote*20/100)
                )}
            </div>
            <p className="quote_subtext">
                This includes all your legal fees, disbursements & VAT.
                <br></br>
                Excludes Stamp Duty.
            </p>
            <table className="table">
                <thead>
                    <tr>
                        <th>{formType} Quote</th>
                        <th>Exc. VAT</th>
                        <th>VAT</th>
                        <th>Inc. VAT</th>
                    </tr>
                </thead>
                <tbody>
                    <tr className="table-primary">
                        <td colSpan="4">Legal Fees</td>
                    </tr>
                    <tr className="fee">
                        <td>Legal Fees</td>
                        <td>£{legalFee}</td>
                        <td>£{legalFee * 20 /100}</td>
                        <td>£{legalFee + legalFee*20/100}</td>
                    </tr>
                    <tr className="table-primary">
                        <td colSpan="4">Additional costs added to base legal fee</td>
                    </tr>
                    <tr className="fee">
                        <td>ID check</td>
                        <td>£{idCheck}</td>
                        <td>£{idCheck*20/100}</td>
                        <td>£{idCheck + idCheck*20/100}</td>
                    </tr>
                    <tr className="fee">
                        <td>Lawyer Checker fee</td>
                        <td>£{lawyerChecker}</td>
                        <td>£{lawyerChecker*20/100}</td>
                        <td>£{lawyerChecker + lawyerChecker*20/100}</td>
                    </tr>
                    <tr className="fee">
                        <td>TT fee (per payment)</td>
                        <td>£{ttFee}</td>
                        <td>£{ttFee*20/100}</td>
                        <td>£{ttFee + ttFee*20/100}</td>
                    </tr>
                    {/* {formType === 'Sale' ? (
                        <tr className="fee">
                            <td>Stamp Duty Land Tax Form</td>
                            <td>£{stampDutyLandTax}</td>
                            <td>£{stampDutyLandTax*20/100}</td>
                            <td>£{stampDutyLandTax + stampDutyLandTax*20/100}</td>
                        </tr>
                    ) : (
                        <Fragment></Fragment>
                    )} */}

                    {props.formData.propertyType === 'Flat' && props.formData.propertyHold === 'Leasehold' ? (
                        <tr className="fee">
                            <td>Leasehold flat/apartment</td>
                            <td>£{leaseholdFlat}</td>
                            <td>£{leaseholdFlat*20/100}</td>
                            <td>£{leaseholdFlat + leaseholdFlat*20/100}</td>
                        </tr>
                    ) : props.formData.propertyType === 'House' && props.formData.propertyHold === 'Leasehold' ? (
                        <tr className="fee">
                            <td>Leasehold house</td>
                            <td>£{leaseholdHouse}</td>
                            <td>£{leaseholdHouse*20/100}</td>
                            <td>£{leaseholdHouse + leaseholdHouse*20/100}</td>
                        </tr>
                    ) :null}

                    {sharedOwner > 0 ? (
                        <tr className="fee">
                            <td>Shared ownership</td>
                            <td>£{sharedOwner}</td>
                            <td>£{sharedOwner*20/100}</td>
                            <td>£{sharedOwner + sharedOwner*20/100}</td>
                        </tr>
                    ) :null}
                    {props.auctionInfo[1].checked === true && (
                        <tr className="fee">
                            <td>Auction sale / purchase</td>
                            <td>£{auctionSalePur}</td>
                            <td>£{auctionSalePur*20/100}</td>
                            <td>£{auctionSalePur + auctionSalePur*20/100}</td>
                        </tr>
                    )}
                    {bridgingFinance > 0 ? (
                        <tr className="fee">
                            <td>Bridging finance</td>
                            <td>£{bridgingFinance}</td>
                            <td>£{bridgingFinance*20/100}</td>
                            <td>£{bridgingFinance + bridgingFinance*20/100}</td>
                        </tr>
                    ) :null }
                    {companiesHouse > 0 ?(
                        <tr className="fee">
                            <td>Registering a charge at Companies House</td>
                            <td>£{companiesHouse}</td>
                            <td>£{companiesHouse*20/100}</td>
                            <td>£{companiesHouse + companiesHouse*20/100}</td>
                        </tr>
                    ) :null}
                    {companiesHouseCharge > 0 ?(
                        <tr className="fee">
                            <td>Companies House charge (per charge)</td>
                            <td>£{companiesHouseCharge}</td>
                            <td>£{companiesHouseCharge*20/100}</td>
                            <td>£{companiesHouseCharge + companiesHouseCharge*20/100}</td>
                        </tr>
                    ) :null}
                    {companiesHouseCharge > 0 ? (
                        <tr className="fee">
                            <td>Land Registry & Bankruptcy searches (company)</td>
                            <td>£{landSearchCompany}</td>
                            <td>£{landSearchCompany*20/100}</td>
                            <td>£{landSearchCompany + landSearchCompany*20/100}</td>
                        </tr>
                    ) :null}
                    <tr className="fee">
                        <td>Land Registry Documents</td>
                        <td>£{landSearch}</td>
                        <td>£{landSearch*20/100}</td>
                        <td>£{landSearch + landSearch*20/100}</td>
                    </tr>
                    <tr className="table-success">
                        {props.formType === 'Remortgage' && props.source === 'Mortgage Advice UK / Whitehorns' ? (
                            <td>Total Discounted quote</td>
                        ) : (
                            <td>Total</td>
                        )}
                        <td>£{totalQuote}</td>
                        <td>£{totalQuote*20/100}</td>
                        <td>£{totalQuote + totalQuote*20/100}</td>
                    </tr>
                    {discount > 0 ? (
                        <>
                            <tr className="table-secondary">
                                <td>Discount</td>
                                <td>£{discount}</td>
                                <td>£{discount*20/100}</td>
                                <td>£{discount + discount*20/100}</td>
                            </tr>
                            <tr className="table-success">
                                <td>Total After Discount</td>
                                <td>£{totalQuote- discount}</td>
                                <td>£{parseInt(totalQuote-discount)*20/100}</td>
                                <td>£{Math.round(parseInt(totalQuote-discount) + parseInt(totalQuote-discount)*20/100)}</td>
                            </tr>
                        </>
                    ) :null}
                </tbody>
            </table>

            <div className="quote_actions">
                <button onClick={instructClickHandler} className="btn btn-success mx-2">Submit</button>
                <PDFDownloadLink document={<MyDoc />} fileName="quote.pdf" className="print_icon">
                    {({ blob, url, loading, error }) =>
                        loading ? 'Loading document...' : <FontAwesomeIcon icon={faPrint} />
                    }
                </PDFDownloadLink>
                <BlobProvider document={<MyDoc />}>
                    {({ blob, url, loading, error }) => {
                        const pdf2base64 = require('pdf-to-base64');
                            pdf2base64(url, {type: "application/pdf"})
                                .then(
                                    (response) => {
                                        // setPdfCode(response)
                                        pdfbase = response
                                    }
                                )
                                .catch(
                                    (error) => {
                                        console.log(error); //Exepection error....
                                    }
                                )
                    }}
                </BlobProvider>
            </div>
            {loading === true ? (
                <div className="loading">
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            ): null}
        </div>
    )
}

export default SaleQuote;